<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <b>{{ confirmTitle }}</b>
    </HeaderCard>

    <v-card-text style="padding-top: 0; padding-bottom: 0">
      <v-form
        ref="form"
        v-model="valid"
        autocomplete="off"
      >
        <IgnoreAutoFill />

        <v-layout
          wrap
          style="padding: 12px 0"
        >
          <v-flex xs12>
            <FieldReadOnly
              icon="mdi-shield-account"
              :label="`field.msg${mfa.method}`"
              v-model="mfa.destination"
            />
          </v-flex>

          <v-flex xs12>
            <FieldSMS
              label="field.code"
              v-model="code"
              autofocus
              @on-enter="confirmMFA"
            />
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>

    <div class="text-center mb-4">
      <v-menu
        :nudge-width="110"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-3 px-0"
            text
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            Reenviar código
          </v-btn>
        </template>
        <v-list
          dense
          text-color="primary"
        >
          <v-list-item
            color="primary"
            @click="resendCode('sms')"
          >
            <v-list-item-icon>
              <v-icon color="primary">
                mdi-message-text
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Por SMS</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item
            color="primary"
            @click="resendCode('whatsapp')"
          >
            <v-list-item-icon>
              <v-icon color="green">
                mdi-account-circle
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Por Whatsapp</v-list-item-title>
            <v-divider />
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <Message
      type="info"
      :show="infoMSG"
      :message="infoMSG"
    />
    <Message
      type="error"
      icon="mdi-cloud-alert"
      :show="showError"
      :message="auth.error"
    />

    <v-divider />

    <v-card-actions style="padding-top: 0.7em; padding-bottom: 0.7em;">
      <v-layout
        wrap
        justify-space-around
      >
        <v-btn
          text
          small
          color="primary"
          :to="linkLogin"
        >
          {{ 'btn.back'.translate() }}
        </v-btn>

        <v-btn
          depressed
          small
          color="primary"
          :disabled="!valid"
          @click="confirmMFA"
          :loading="loading"
        >
          {{ 'btn.confirm'.translate() }}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/components/Message'
import HeaderCard from '@/components/HeaderCard'
import FieldReadOnly from '@/components/fields/FieldReadOnly'
import FieldSMS from '@/components/fields/FieldSMS'
import { ROUTE_AUTH_LOGIN, ACTION_USER_MFA, ACTION_USER_CODE } from '@/constants'
import IgnoreAutoFill from './IgnoreAutoFill'

export default {
  components: {
    Message,
    HeaderCard,

    FieldSMS,
    FieldReadOnly,
    IgnoreAutoFill
  },

  data () {
    return {
      code: null,
      valid: true,
      loading: false,
      showError: false,
      confirmTitle: 'Confirmar Código'
    }
  },

  methods: {
    confirmMFA () {
      if (!this.valid) return false
      this.loading = true
      const { code } = this
      this.$store.dispatch(ACTION_USER_MFA, { code })
    },

    resendCode (type) {
      this.$store.dispatch(ACTION_USER_CODE, { type })
    }
  },

  computed: {
    linkLogin: () => ({ name: ROUTE_AUTH_LOGIN }),

    ...mapState({
      auth: ({ auth }) => ({ error: auth.error, loading: auth.loading }),
      infoMSG: ({ auth }) => auth.info ? auth.info : null,

      mfa: ({ auth }) => {
        if (!auth?.userCognito) return {}

        return {
          method: auth.userCognito?.challengeParam?.CODE_DELIVERY_DELIVERY_MEDIUM,
          destination: auth.userCognito?.challengeParam?.CODE_DELIVERY_DESTINATION
        }
      }
    })
  },

  watch: {
    code () {
      this.showError = false
    },

    'auth.loading': function (loading) {
      if (this.loading !== loading) this.loading = loading
    },

    'auth.error': function (error) {
      this.showError = Boolean(error)
    }
  }
}
</script>
