<template>
  <v-text-field
    type="number"
    v-model="sms"
    autocomplete="off"
    :rules="smsRules"
    :label="i18n(label)"
    :outlined="outlined"
    :readonly="readonly"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    @keyup.enter="$emit('on-enter', $event)"
  />
</template>

<script>
import { smsRules } from '@/rules'

const onlyString = (str) => String(str || '').trim()

export default {
  props: {
    icon: { default: 'mdi-shield-lock-outline' },
    value: {},
    label: { default: 'field.sms' },
    outlined: { default: false },
    readonly: { default: false },
    autofocus: { default: false }
  },

  data () {
    return {
      sms: onlyString(this.value),
      smsRules
    }
  },

  watch: {
    sms () {
      const sms = onlyString(this.sms)
      const changed = sms !== onlyString(this.value)
      if (changed) this.$emit('input', sms)
    },

    value () {
      const value = onlyString(this.value)
      const changed = value !== onlyString(this.sms)
      if (changed) this.sms = value
    }
  }
}
</script>
