<template>
  <v-text-field
    type="text"
    v-model="str"
    readonly
    :label="i18n(label)"
    :outlined="outlined"
    :prepend-inner-icon="icon"
  />
</template>

<script>
export default {
  props: {
    icon: {},
    value: {},
    label: {},
    outlined: { default: false }
  },

  data () {
    const str = this.value || ''
    return { str }
  },

  watch: {
    value (value) {
      this.str = value || ''
    }
  }
}

</script>
